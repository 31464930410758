import * as React from 'react'
import * as cn from 'classnames';
/**
 * Учитывая, что postcss-color-function не работет с styled-components, я решил внедрить свою реализацию styled components, где стили будут описываться в css модулях.
 * @param {Component} cmp 
 * @param {String} cls 
 * @returns Component
 * @example 
 * const CoursesList = style('div', cls.coursesList);
 * const Course = style(CourseCard, cls.course);
 */
export const style = (cmp, cls) => ({
  className,
  children,
  ...props
}) => React.createElement(cmp, {
  className: cn(cls, className),
  ...props
}, children);